import { CommonModule } from '@angular/common';
import { Component, ViewChild, input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { RouterLink, RouterLinkActive } from '@angular/router';

@Component({
  selector: 'husky-sidenav-item',
  standalone: true,
  imports: [CommonModule, RouterLink, RouterLinkActive, MatIcon],
  templateUrl: './sidenav-item.component.html',
  styleUrl: './sidenav-item.component.scss',
})
export class SidenavItemComponent {
  label = input.required<string>();
  path = input.required<string>();
  icon = input.required<string>();
  routerLinkActiveOptions = input<{ exact: boolean }>({ exact: false });

  @ViewChild(RouterLinkActive) routerLinkActive?: RouterLinkActive;
}
