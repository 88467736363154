import { BreakpointObserver } from '@angular/cdk/layout';
import { Location } from '@angular/common';
import { Component, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDrawerMode } from '@angular/material/sidenav';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

import { Breakpoints } from '@husky/app-shared-util-breakpoint';

@Component({
  selector: 'husky-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
})
export class LayoutComponent {
  sidenavMode = signal<MatDrawerMode>('side');
  sidenavOpen = signal<boolean>(true);

  constructor(
    private readonly breakpointObserver: BreakpointObserver,
    private readonly router: Router,
    private readonly location: Location,
  ) {
    this.breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
      ])
      .pipe(takeUntilDestroyed())
      .subscribe((result) => {
        this.sidenavMode.set(result.matches ? 'over' : 'side');
        this.sidenavOpen.set(!result.matches);
      });

    this.router.events
      .pipe(
        takeUntilDestroyed(),
        filter(() => this.sidenavMode() === 'over'),
        filter((event) => event instanceof NavigationEnd),
      )
      .subscribe(() => {
        this.sidenavOpen.set(false);
      });
  }
}
